<template>
  <div class="outter" v-if="newsList.length > 0">
    <div class="ad_banner coverWidth clearFix">
      <img src="../assets/image/index/home-banner.png" alt="">
    </div>
    <div class="main main1">
      <div class="container">
        <div class="title_light">
          <img class="news" src="../assets/image/index/news.png" alt="">
        </div>
        <div class="main1_in clearFix">
          <div class="fl">
            <div class="left clearFix" @click="to('/detail?id='+ newsList[0].id)">
              <div class="fl left_img">
                <img :src="newsList[0].imgUrl" alt="">
              </div>
              <div class="fl left_text">
                <h1 class="ellipsis1">{{newsList[0].title}}</h1>
                <div class="timer">{{newsList[0].date}}</div>
                <p class="ellipsis2">{{newsList[0].desc}}</p>
                <div class="button">了解详情</div>
              </div>
            </div>
            <div class="line"></div>
            <div class="left clearFix"  @click="to('/detail?id='+ newsList[1].id)">
              <div class="fl left_img">
                <img :src="newsList[1].imgUrl" alt="">
              </div>
              <div class="fl left_text">
                <h1 class="ellipsis1">{{newsList[1].title}}</h1>
                <div class="timer"><span>{{newsList[1].date}}</span></div>
                <p class="ellipsis2">{{newsList[1].desc}}</p>
                <div class="button">了解详情</div>
              </div>
            </div>
          </div>
          <div @click="to('/detail?id='+ newsList[2].id)" class="fr right">
            <h1 class="ellipsis1">{{newsList[2].title}}</h1>
            <div class="timer">{{newsList[2].date}}</div>
            <p class="ellipsis2">{{newsList[2].desc}}</p>
            <el-carousel trigger="click" height="232px" class="carousel" :interval="interval" autoplay>
              <el-carousel-item v-for="(item, index) in newsList[2].carouselData" :key="index">
                <img :src="item.imgUrl" alt="">
              </el-carousel-item>
            </el-carousel>

          </div>
        </div>
      </div>
    </div>
    <div class="main main2">
      <div class="container">
        <div class="title_light">
          <img class="service" src="../assets/image/index/service.png" alt="">
        </div>
        <div class="main2_in clearFix">
          <div class="main2_in_item fl">
            <img src="../assets/image/index/service1.png" alt="">
            <p>药联直付服务</p>
            <div class="service-content">药联直付服务是药联健康联合大型保险公司共同推出的基于商业保险的健康直赔服务。基于闭环交易系统的服务化理赔新方式。</div>
            <div class="button">了解详情</div>
          </div>
          <div class="main2_in_item fl">
            <img src="../assets/image/index/service2.png" alt="">
            <p>药联增值服务</p>
            <div class="service-content">药联增值服务是药联健康联合大型保险公司针对购药等健康消费需求下的不同年龄人群、特定慢病人群等推出的健康增值服务产品，为消费者在健康消费及健康保障方面提供整体解决方案，降低消费者用药成本，提高风险抵抗能力。</div>
            <div class="button">了解详情</div>
          </div>
          <div class="main2_in_item fl">
            <img src="../assets/image/index/service3.png" alt="">
            <p>保司联合运营</p>
            <div class="service-content">药联基于自身运营能力、平台优势、精准智能数据、多样化营销工具等，联合保司基础服务和产品，进行产品组合创新、数据支撑、创新使用裂变工具和增值服务，维护“脱保”用户、促活存量用户、裂变新用户、提升留存和转化。</div>
            <div class="button">了解详情</div>
          </div>
          <div class="main2_in_item fl">
            <img src="../assets/image/index/service4.png" alt="">
            <p>药店联合运营</p>
            <div class="service-content">药联利用自身的各项优势，帮助连锁提高店内转化、店外获客、创新提供药联智盈后台，7X24全方位监控业务数据，掌握竞争对手情况，并提供各种工具锁定客户到店，提升客单价，增加客户满意度，从而大幅提升连锁的利润额。</div>
            <div class="button">了解详情</div>
          </div>
        </div>
      </div>
    </div>
    <div class="main main3">
      <div class="ability"></div>
      <div class="drug"></div>
      <div class="container">
        <div class="title_light">
          <img src="../assets/image/index/core.png" alt="">
        </div>
        <div class="main3_in clearFix">
          <div class="main3_in_item">
            <div class="main3_in_item-pic"><img src="../assets/image/index/ability1.png" alt=""></div>
            <h1 class="word-weight">先进的技术平台</h1>
            <p class="word-article">药联健康通过与合作连锁的对接，实现了整个消费过程的全闭环。确保商保消费的合规，安全。不同于传统的模式，从流程根本上解决商保的合规安全风险，同时也确保了整个闭环的可控性和高效性。</p>
            <div class="knowMore">了解详情 ></div>
          </div>
          <div class="main3_in_item">
            <div class="main3_in_item-pic"><img src="../assets/image/index/ability2.png" alt=""></div>
            <h1 class="word-weight">规模化的服务网路</h1>
            <p class="word-article">药联健康已接入连锁药店10万余家，2020年年底预计预测突破15万家门店。占全国连锁药店的29%，持有药联商保出单APP的注册店员约30万人，药品平均月交易订单量84.9万单。依托完善的药店覆盖，药联O2O到家服务已陆续在各大城市上线，提供更好更快的购药服务。</p>
            <div class="knowMore">了解详情 ></div>
          </div>
          <div class="main3_in_item">
            <div class="main3_in_item-pic"><img src="../assets/image/index/ability3.png" alt=""></div>
            <h1 class="word-weight">数据驱动的运营及服务</h1>
            <p class="word-article">药联用户大数据，根据地区、年龄、性别、职业、车主、高血压、心脑血管疾病、糖尿病、各种病症、购药习惯、保健习惯、需求健康知识、兴趣爱好、社群群体、活动喜好、身体健康数据、病症周期、服药周期、基因检测数据、咨询交互数据等等多维度交叉矩阵属性，在最细的颗粒度上构建用户画像，精准匹配业务场景和服务。</p>
            <div class="knowMore">了解详情 ></div>
          </div>
          <div class="main3_in_item">
            <div class="main3_in_item-pic"><img src="../assets/image/index/ability4.png" alt=""></div>
            <h1 class="word-weight">专业的属地化服务团队</h1>
            <p class="word-article">目前药联保险业务团队在全国各区域均配置有专职商务总监，负责和保险公司伙伴的合作沟通为保险公司在合作的前、中、后提供全面细致的服务支持。</p>
            <div class="knowMore">了解详情 ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="main main4">
      <div class="title_light">
        <img src="../assets/image/index/partner.png" alt="">
      </div>
      <div class="main4_in clearFix">
        <!-- <div class="btn btnDisable fl">
          <img src="../assets/image/page2/arrowL.png" alt="">
        </div> -->
        <div class="main4_in_con clearFix fl">
          <el-carousel indicator-position='none' height="86px" :interval="5000" arrow="always">
            <el-carousel-item>
              <img class="imgStore" src="../assets/image/logo/p1.png" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img class="imgStore" src="../assets/image/logo/p2.png" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img class="imgStore" src="../assets/image/logo/p3.png" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img class="imgStore" src="../assets/image/logo/p4.png" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img class="imgStore" src="../assets/image/logo/p5.png" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img class="imgStore" src="../assets/image/logo/p6.png" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img class="imgStore" src="../assets/image/logo/p7.png" alt="">
            </el-carousel-item>
          </el-carousel>
          <div class="more">查看更多<img src="../assets/image/arror_color.png" alt=""></div>

        </div>
        <!-- <div class="btn btnDisable fr">
          <img src="../assets/image/page2/arrowR.png" alt="">
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
import { Carousel, CarouselItem } from 'element-ui'

export default {
  name: 'home',
  data() {
    return {
      newsList: [],
      interval: 10000,
      autoplay: false
    }
  },
  created() {
    this.$post('/news/list', {
      isIndex: 1, page: 1, limit: 5
    }).then(res => {
      this.newsList = res.data.body;
    })
  },
  methods: {
    to(e) {
      window.scrollTo(0, 0)
      if (e != this.$route.path) {
        let jump = this.$router.resolve({
          path: e
        })
        window.open(jump.href, '_blank')
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/css/mixin.less';
.button {
  width: 92px;
  height: 32px;
  background: #00B095;
  border-radius: 20px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  box-shadow: 0px 2px 15px 0px rgba(0, 176, 149, 0.26);
}
.banner {
  width: 100%;
  // min-width: 1200px;
  position: relative;

  .txt {
    position: absolute;
    width: 1044px;
    height: 188px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    span {
      width: 500px;
      height: 156px;
      color: #fff;
      font-size: 70px;
      text-align: center;
      line-height: 156px;
    }
    span:first-child {
      background: #00cdb7;
    }
    span:last-child {
      background: #0dabb1;
    }
  }
}
.main {
  width: 100%;
  // min-width: 1200px;
  position: relative;
}
.main1 {
  padding: 70px 0;
  .title_light {
    .news {
      .wh(120px, 64px);
    }
  }

  .main1_in {
    width: 1200px;
    margin: 40px auto 0;
    position: relative;

    .line {
      .wh(559px, 1px);
      background-color: #eeeeee;
    }

    .left {
      width: 581px;
      padding: 20px 0 0;
      margin-bottom: 25px;
      cursor: pointer;
      box-sizing: border-box;
      .left_img {
        width: 248px;
        height: 201px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
        }
      }
      .left_text {
        width: 279px;
        padding: 15px 0 0 30px;
        h1 {
          font-size: 20px;
          counter-reset: #333333;
        }
        .timer {
          .font(16px, #F7B500);
          padding: 10px 0 18px;
          vertical-align: middle;
          img {
            width: 13px;
            height: 14px;
            vertical-align: middle;
            padding-right: 10px;
          }
        }
        p {
          .font(16px, #777777);
          line-height: 23px;
          text-align: justify;
          margin-bottom: 28px;
        }
      }
    }
    .right {
      .wh(600px, 492px);
      position: relative;
      cursor: pointer;
      background-color: #f6f6f6;
      padding: 40px 44px 0 40px;
      box-sizing: border-box;
      border-radius: 10px;

      h1 {
        .font(24px, #333);
        font-family: PingFangSC-Medium, PingFang SC;
        margin: 0 0 16px;
      }

      .ellipsis2 {
        .font(16px, #777777);
        line-height: 23px;
        margin: 30px 0;
        text-align: justify;
      }

      .timer {
        .font(20px, #666);
      }

      .carousel {
        img {
          width: 100%;
          height: 232px;
          border-radius: 20px;
          object-fit: cover;
        }
      }
    }
    .right:hover {
      box-shadow: 0px 2px 11px 0px rgba(228, 227, 227, 1);
    }
  }
}
.main2 {
  padding: 0 0 70px;

  .title_light img {
    .wh(523px, 64px);
  }

  .main2_in {
    padding: 43px 0 0;

    .main2_in_item {
      width: 278px;
      height: 443px;
      background: #fff;
      position: relative;
      box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.06);
      margin-right: 29px;
      transition: .2s all linear;
      cursor: pointer;
      border-radius: 20px;

      &:last-child {
        margin-right: 0px;
      }

      >img {
        .wh(278px, auto);
      }

      >p {
        .font(18px, #333);
        padding: 20px 20px 16px;
      }

      .service-content {
        .font(14px, #777);
        line-height: 24px;
        padding: 0 20px;
        text-align: justify;
      }
      .button {
        position: absolute;
        bottom: 30px;
        right: 23px;
        display: none;
        transition: .2s all linear;
      }
    }

    .main2_in_item:hover {
      transform: scale(1.02);
      box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.06);
      .button {
        display: block;
      }
    }
  }
}
.main3 {
  padding: 68px 0 0;
  height: 780px;
  background-color: #EBF9F7;
  position: relative;
  box-sizing: border-box;

  .title_light img {
    position: relative;
    z-index: 50;
    .wh(311px, 64px);
  }

  .ability {
    position: absolute;
    .wh(55%, 84px);
    background: url('../assets/image/index/ability.png') no-repeat;
    background-size: cover;
    left: 40px;
    bottom: 37px;
    z-index: 0;
  }

  .drug {
    position: absolute;
    .wh(26%, 404px);
    background: url('../assets/image/index/yao.png') no-repeat;
    background-size: cover;
    right: 0;
    bottom: 0;
    z-index: 0;
  }

  .main3_in {
    padding: 40px 0 0 23px;

    .main3_in_item {
      width: 561px;
      height: 259px;
      background-color: #fff;
      box-sizing: border-box;
      float: left;
      position: relative;
      cursor: pointer;
      padding: 43px 42px 0 114px;
      border-radius: 20px;
      margin-bottom: 30px;
      opacity: .9;
      transition: .2s all linear;

      &:hover {
        transform: scale(1.02);
        box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.06);
        opacity: 1;

        .knowMore {
          display: block;
        }
      }

      &:nth-of-type(2n-1) {
        margin-right: 48px;
      }

      &-pic {
        position: absolute;
        top: 25px;
        left: -24px;


        img {
          .wh(108px, 108px);
        }
      }

      p {
        padding: 17px 0 0;
        line-height: 24px;
      }

      .knowMore {
        .font(14px, #00B095);
        position: absolute;
        right: 43px;
        bottom: 30px;
        display: none;
      }
    }
  }
}
.main4 {
  padding: 71px 0 0;
  height: 339px;
  box-sizing: border-box;

  .title_light img {
    .wh(143px, 63px);
  }

  .main4_in {
    width: 1200px;
    margin: 40px auto 0;
    .btn {
      width: 40px;
      height: 56px;
      background: #00cdb7;
      display: flex;
      align-items: center;
      margin-top: 14px;
      cursor: pointer;
      img {
        width: 13px;
        height: 24px;
        margin: auto;
      }
    }
    .btn.btnDisable {
      background: rgba(0, 0, 0, 0.12);
    }
    .main4_in_con {
      width: 1200px;
      margin-left: 0px;
      overflow: hidden;
      box-sizing: border-box;
      .imgStore {
        margin: 14px 0 0 32px;
      }
    }
    .more {
      text-align: right;
      color: #00B095;
      font-size: 14px;
      margin-top: 15px;
      cursor: pointer;
      padding: 0 80px 0 0;

      img {
        padding: 0 0 0 6px;
        vertical-align: middle;
      }
    }
  }
  .main4_in /deep/ .el-carousel__arrow:hover {
      background-color: #00B095;
      .el-icon-arrow-left {
        color: #fff;
      }
      .el-icon-arrow-right {
        color: #fff;
      }
  }
}
</style>
<style lang="less">
.swiper-slide {
  height: 300px;
}
.main4 {
  .main4_in {
    .main4_in_con {
      .el-carousel.el-carousel--horizontal {
        overflow-x: inherit;
      }
      .el-carousel__arrow {
        width: 36px;
        height: 50.4px;
        background: #ddd;
        display: flex;
        align-items: center;
        margin-top: 14px;
        cursor: pointer;
        border-radius: 0;
        font-size: 28px;
        top: 29px;
        text-align: center;
        i {
          margin: auto;
          font-size: 20px;
          color: #979797;
        }
      }
      .el-carousel__arrow--right {
        right: 0;
      }
      .el-carousel__arrow--left {
        left: 0;
      }
    }
  }
}
</style>
